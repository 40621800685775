<template>
  <section class="container">
    <div class="navigation-wrapper">
      <!-- Navigation Top -->
      <div class="navigation-container">
        <a href="/" target="_self">
          <img alt="KHN Logo" class="navigation-logo" src="/assets/logos/khn_logo.svg" />
        </a>

        <div class="navigation-top">
          <form method="get" action="/zoeken">
            <div class="search-wrapper">
              <input type="search" name="term" placeholder="Zoek direct naar..." />
              <div class="search-icon">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17 10.5C17 11.9344 16.5344 13.2594 15.75 14.3344L19.2063 17.7937L19.9156 18.5L18.5 19.9156L17.7937 19.2094L14.3344 15.75C13.2594 16.5375 11.9344 17 10.5 17C6.90937 17 4 14.0906 4 10.5C4 6.90937 6.90937 4 10.5 4C14.0906 4 17 6.90937 17 10.5ZM10.5 15C11.0909 15 11.6761 14.8836 12.2221 14.6575C12.768 14.4313 13.2641 14.0998 13.682 13.682C14.0998 13.2641 14.4313 12.768 14.6575 12.2221C14.8836 11.6761 15 11.0909 15 10.5C15 9.90905 14.8836 9.32389 14.6575 8.77792C14.4313 8.23196 14.0998 7.73588 13.682 7.31802C13.2641 6.90016 12.768 6.56869 12.2221 6.34254C11.6761 6.1164 11.0909 6 10.5 6C9.90905 6 9.32389 6.1164 8.77792 6.34254C8.23196 6.56869 7.73588 6.90016 7.31802 7.31802C6.90016 7.73588 6.56869 8.23196 6.34254 8.77792C6.1164 9.32389 6 9.90905 6 10.5C6 11.0909 6.1164 11.6761 6.34254 12.2221C6.56869 12.768 6.90016 13.2641 7.31802 13.682C7.73588 14.0998 8.23196 14.4313 8.77792 14.6575C9.32389 14.8836 9.90905 15 10.5 15Z"
                    fill="#585858"
                  />
                </svg>
              </div>
            </div>
          </form>

          <ul v-if="menu.externalLinks" class="navigation-list">
            <li
              v-for="(externalLink, index) in menu.externalLinks"
              :key="index"
              :class="{ 'navigation-item': true, 't-navigation-small': !externalLink.redButton }"
            >
              <a
                :href="externalLink.url"
                :class="{ 'button-primary': externalLink.redButton }"
                target="_blank"
                >{{ externalLink.title }}</a
              >
            </li>
          </ul>
        </div>
      </div>

      <!-- Navigation Bottom -->
      <div class="navigation-container">
        <ul class="navigation-list">
          <li class="navigation-item t-navigation">
            <a :href="menu.overviewPage.url">
              {{ menu.overviewPage.title }}
            </a>
          </li>
          <li v-for="item in menu.mainNav" :key="item.title" class="navigation-item t-navigation">
            <a :href="item.url">{{ item.title }}</a>
          </li>
        </ul>

        <ul class="navigation-list">
          <li v-for="item in menu.subNav" :key="item.title" class="navigation-item t-navigation">
            <a :href="item.url" class="t-normal">{{ item.title }}</a>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'NavigationDesktop',
  props: {
    menu: {
      type: Object,
      required: true,
    },
  },
};
</script>
